<template>
  <div :class="$style.products">
    <div :class="$style.wrapper">
      <el-button @click="isOpenOptionsModal = true">
        Дополнительные опции
      </el-button>

      <el-button @click="isOpenWarehouseModal = true">
        Количество на складах
      </el-button>

      <a
        target="_blank"
        :href="$configData.addwine_link + 'catalogue/' + form.slug"
      >
        <el-button size="small" type="success"> На страницу товара </el-button>
      </a>
      <el-button size="small" type="primary" @click="submitForm('form')">
        Сохранить
      </el-button>
      <el-button size="small" type="primary" @click="SaveAndComeBack('form')">
        Сохранить и вернуться
      </el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="8.5rem"
    >
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name" readonly />
      </el-form-item>
      <el-form-item label="Имя в url" prop="slug">
        <el-input v-model="form.slug" clearable></el-input>
      </el-form-item>
      <el-form-item label="Артикул" prop="vendorCode">
        <el-input v-model="form.vendorCode"></el-input>
      </el-form-item>

      <el-form-item
        label="Отображать на сайте"
        prop="isActive"
        label-width="12rem"
      >
        <input
          type="checkbox"
          :checked="form.isActive"
          v-model="form.isActive"
        />
      </el-form-item>

      <el-form-item label="мой склад ID" prop="moySkladId">
        <el-input readonly v-model="form.moySkladId" clearable></el-input>
      </el-form-item>
      <el-form-item label="Поставщики">
        <el-input
          :value="this.form.suppliers"
          :rows="3"
          type="textarea"
          placeholder="Поставщики"
        />
      </el-form-item>
      <el-form-item label="Предназначение" prop="purpose">
        <el-input v-model="form.purpose" />
      </el-form-item>
      <el-form-item label="Бренд" prop="brandId">
        <el-select
          v-model="form.brandId"
          placeholder="Выберите"
          style="width: 100%"
          v-el-select-loadmore="loadMoreBrands"
          filterable
          remote
          :remote-method="searchBrands"
          :loading="brandsLoading"
        >
          <el-option
            v-for="item in brands"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Категории" prop="categories">
        <el-cascader
          v-model="form.categories"
          :options="categories"
          :props="{
            label: 'name',
            value: 'id',
            multiple: true,
            checkStrictly: true,
            emitPath: false,
          }"
          style="width: 100%"
          collapse-tags
          filterable
        />
      </el-form-item>

      <el-form-item label="Ассортименты" prop="assortments">
        <el-select
          v-model="form.assortments"
          filterable
          placeholder="Выбрать"
          clearable
          multiple
          remote
          :remote-method="searchAssortments"
          v-el-select-loadmore="loadMoreAssortments"
          :loading="assortmentsLoading"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in assortments"
            :key="item.id + item.slug + index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Группа" prop="groupId">
        <el-select
          v-model="form.groupId"
          placeholder="Выберите"
          style="width: 100%"
          v-el-select-loadmore="loadMoreGroups"
          @change="getAttributeList"
          @click.native="confirmChangeGroup"
          ref="selectGroup"
        >
          <el-option
            v-for="item in groups"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Атрибуты" v-if="form.groupId">
        <br />
        <el-form-item
          v-for="att in attributes"
          :key="att.id"
          :label="att.name"
          :class="$style.filterItem"
          label-width="0"
        >
          <el-select
            v-model="att.valueOptions"
            multiple
            placeholder="Выберите"
            style="width: 100%"
            @remove-tag="deleteAttributeOptions"
            filterable
          >
            <el-option
              v-for="item in att.attributeOptions"
              :key="item.id"
              :label="item.value"
              :value-key="item.id"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form-item>

      <el-form-item label="Подарок" prop="presentId">
        <el-select
          v-model="form.presentId"
          placeholder="Выберите"
          style="width: 100%"
          v-el-select-loadmore="loadMorePresents"
          filterable
          remote
          clearable
          :remote-method="searchPresents"
          :loading="presentsLoading"
        >
          <el-option
            v-for="item in presents"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Приоритет" prop="priority">
        <el-input-number
          v-model.number="form.priority"
          controls-position="right"
          :min="0"
        >
        </el-input-number>
      </el-form-item>

      <div :class="$style.prices">
        <el-form-item label="Себестоимость" prop="costPrice">
          <el-input
            readonly
            v-model.number="form.costPrice"
            controls-position="right"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Цена" prop="price">
          <el-input
            readonly
            v-model.number="form.price"
            controls-position="right"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="После скидки" prop="discountPrice">
          <el-input
            readonly
            v-model.number="form.discountPrice"
            controls-position="right"
          >
          </el-input>
        </el-form-item>
      </div>
      <el-form-item label="Описание" prop="description">
        <el-input
          readonly
          :value="form.description"
          :rows="3"
          type="textarea"
          placeholder="Описание"
        />
      </el-form-item>
      <el-form-item label="Фото товара" prop="images">
        <ul :class="$style.imageList">
          <li v-for="image in form.images" :key="image.original">
            <img :src="$configData.s3_link + image.original" alt="" />
          </li>
        </ul>
      </el-form-item>

      <SeoBlock
        :hasSetDefaultFlag="true"
        :setDefault.sync="form.seo.setDefault"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
      />
    </el-form>

    <ProductStocksModal
      :is-visible="isOpenWarehouseModal"
      :data="getProductStocksData()"
      @close="isOpenWarehouseModal = false"
    />

    <el-drawer
      title="Дополнительные опции"
      :visible.sync="isOpenOptionsModal"
      direction="rtl"
      size="40%"
    >
      <div v-if="form.additionalOptions" :class="$style.drawerItems">
        <table>
          <tr
            v-for="(option, index) in additionalOptionsObject"
            :key="option.id"
          >
            <td>{{ option }}</td>
            <td>{{ form.additionalOptions[index] }}</td>
          </tr>
        </table>
      </div>
      <div v-else>Нет дополнительных опций</div>
    </el-drawer>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'

import { debounce } from '@/helpers/debounce.js'
import { parseProductPresence } from '@/helpers/addwine/products'

import SeoBlock from '@/components/moleculs/SEO.vue'
import ProductStocksModal from '@/components/moleculs/ProductStocksModal.vue'

export default {
  components: { SeoBlock, ProductStocksModal },
  mixins: [notifications],
  data() {
    return {
      isOpenWarehouseModal: false,
      isOpenOptionsModal: false,
      additionalOptionsObject: {
        barcode: 'Штрих-код',
        color: 'Цвет',
        countryOfOrigin: 'Страна производитель',
        diameter: 'Диаметр',
        equipment: 'Комплектация',
        height: 'Высота',
        material: 'Материал',
        packingDimensions: 'Размер упаковки',
        qtyPerSet: 'Количество в наборе',
        volume: 'Объем',
        weight: 'Вес',
      },
      startValue: {
        groupId: '',
        categories: [],
        assortments: [],
        attributeValues: [],
      },
      groups: [],
      groupsOptions: {
        limit: 20,
        page: 1,
        total: 0,
      },
      brands: [],
      brandsLoading: false,
      brandsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
        orderDir: 'asc',
        orderBy: 'name',
      },
      categories: [],
      categoriesLoading: false,
      categoriesOptions: {
        limit: 8,
        page: 1,
        total: 0,
        search: '',
      },
      assortments: [],
      assortmentsLoading: false,
      assortmentsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
      },
      suppliers: [],
      presents: [],
      presentsLoading: false,
      presentsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
        orderDir: 'asc',
        orderBy: 'name',
      },
      moySkladId: '',
      attributes: [],
      defaultAssortments: [],
      form: {
        name: '',
        slug: '',
        moySkladId: '',
        groupId: '',
        brandId: '',
        presentId: '',
        description: '',
        vendorCode: '',
        categories: [],
        assortments: [],
        images: [],
        priority: 0,
        additionalOptions: null,
        isActive: false,
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
          setDefault: false,
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        vendorCode: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        description: [
          {
            trigger: 'change',
          },
        ],
        moySkladId: [
          {
            trigger: 'blur',
          },
        ],
        groupId: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        brandId: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        categories: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
        },
      },
      inputTagVisible: false,
      inputTagValue: '',
    }
  },
  async created() {
    await this.getProduct()
    this.getGroupList()
    this.getBrandList()
    this.getOptions()
    this.getAssortmentList()
    this.setDefaultAssortments()
    this.getPresentList()
    this.brandSearch = debounce(this.getBrandList, 500)
    this.categorySearch = debounce(this.getCategoryList, 500)
    this.assortmentSearch = debounce(this.getAssortmentList, 500)
    this.presentSearch = debounce(this.getPresentList, 500)
  },
  methods: {
    async getProduct() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ProductsCore.ProductsActions.getById(
          this.$route.params.id,
        )

      loading.close()

      if (error) return
      this.form = { ...this.form, ...value }
      this.startValue = { ...value }
      const categories = value.categories?.map((i) => i.id)

      if (value.assortments) this.defaultAssortments = [...value.assortments]
      const assortments = value.assortments?.map((i) => i.id)
      this.startValue.assortments = assortments
      this.startValue.categories = categories
      this.form.assortments = assortments
      this.form.categories = categories
      this.form.moySkladId = value.moySkladId

      await this.getAttributeList()

      this.attributes.forEach((attribute) => {
        const values = value.attributeValues
        if (values && values.length) {
          values.forEach((i) => {
            if (i.attributeId === attribute.id) {
              attribute.valueOptions = i.attributeOptions
            }
          })
        }
      })
    },
    loadMoreGroups() {
      const { page, limit, total } = this.groupsOptions
      if (limit * page < total) {
        this.groupsOptions.page++
        this.getGroupList()
      }
    },
    loadMoreBrands() {
      const { page, limit, total } = this.brandsOptions
      if (limit * page < total) {
        this.brandsOptions.page++
        this.getBrandList()
      }
    },
    async searchBrands(search) {
      this.brandsOptions.page = 1
      this.brandsOptions.search = search
      this.brandsLoading = true
      this.brands = []
      await this.brandSearch()
    },
    loadMoreAssortments() {
      const { page, limit, total } = this.assortmentsOptions
      if (limit * page < total) {
        this.assortmentsOptions.page++
        this.getAssortmentList()
      }
    },
    async searchAssortments(search) {
      this.assortmentsOptions.page = 1
      this.assortmentsOptions.search = search
      this.assortmentsLoading = true
      this.assortments = []
      await this.assortmentSearch()
    },
    loadMorePresents() {
      const { page, limit, total } = this.presentsOptions
      if (limit * page < total) {
        this.presentsOptions.page++
        this.getPresentList()
      }
    },
    async searchPresents(search) {
      this.presentsOptions.page = 1
      this.presentsOptions.search = search
      this.presentsLoading = true
      this.presents = []
      await this.presentSearch()
    },
    async getGroupList() {
      const { value, error } =
        await delivery.ProductsCore.GroupsActions.getList({
          limit: this.groupsOptions.limit,
          page: this.groupsOptions.page,
          orderDir: 'asc',
          orderBy: 'name',
        })
      if (error) return
      this.groups = [...this.groups, ...value.data]

      this.groupsOptions.total = value.meta.count
    },

    async confirmChangeGroup() {
      const isConfirm = confirm(
        'Вы точно хотите сменить группу товара? В результате все существующие атрибуты товара и их значения будут обнулены.',
      )
      if (!isConfirm) {
        this.$refs.selectGroup.blur()
      } else {
        await this.deleteAttributeValues()
        this.getAttributeList()
      }
    },

    async getCategoryList() {
      this.categoriesLoading = true

      const { value, error } =
        await delivery.ProductsCore.CategoriesActions.getList(
          this.categoriesOptions,
        )
      if (error) return
      this.categories = [...this.categories, ...value.data]
      this.categoriesOptions.total = value.meta.count

      this.categoriesLoading = false
    },

    setDefaultAssortments() {
      this.defaultAssortments.forEach((assortment) => {
        if (!this.assortments.find((x) => x.id === assortment.id)) {
          this.assortments.unshift(assortment)
        }
      })
    },

    async getAssortmentList() {
      this.assortmentsLoading = true

      const { value, error } =
        await delivery.ProductsCore.AssortmentsActions.getList(
          this.assortmentsOptions,
        )
      if (error) return
      value.data.forEach((x) => {
        if (!this.assortments.find((y) => y.id === x.id))
          this.assortments.push(x)
      })
      this.assortmentsOptions.total = value.meta.count

      this.assortmentsLoading = false
    },

    async deleteProductCategories() {
      const requests = this.startValue.categories
        .filter((item) => {
          return !this.form.categories.includes(item)
        })
        .map((item) => {
          return delivery.ProductsCore.ProductCategoriesActions.delete({
            productIds: [this.$route.params.id],
            categoryId: item,
          })
        })

      Promise.all(requests)
    },

    async getPresentList() {
      this.presentsLoading = true

      const { value, error } =
        await delivery.ProductsCore.ProductsActions.getList(
          this.presentsOptions,
        )
      if (error) return
      this.presents = [...this.presents, ...value.data]
      this.presentsOptions.total = value.meta.count

      this.presentsLoading = false
    },

    async deleteProductAssortments() {
      const requests = this.startValue.assortments
        .filter((item) => {
          return !this.form.assortments.includes(item)
        })
        .map((item) => {
          return delivery.ProductsCore.ProductAssortmentsActions.delete({
            productIds: [this.$route.params.id],
            assortmentId: item,
          })
        })

      Promise.all(requests)
    },
    async getOptions() {
      Promise.all([delivery.ProductsCore.CategoriesActions.getTree()]).then(
        (results) => {
          const [categoriesData] = results
          if (!categoriesData.error && categoriesData.value.children?.length) {
            this.categories = categoriesData.value.children
          }
        },
      )
    },
    async getBrandList() {
      this.brandsLoading = true
      const { value, error } =
        await delivery.ProductsCore.BrandsActions.getList(this.brandsOptions)
      if (error) return
      this.brands = [...this.brands, ...value.data]

      this.brandsOptions.total = value.meta.count

      this.brandsLoading = false
    },
    async getAttributeList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ProductsCore.AttributesActions.getList({
          limit: this.limit,
          page: this.page,
          groupId: this.form.groupId,
        })
      if (error) return
      this.attributes = value.data
      this.attributes.forEach((attr) => {
        attr.attributeOptions.sort((x, y) => {
          if (x.value.toLowerCase() < y.value.toLowerCase()) return -1
          else return 1
        })
      })
      this.total = value.meta.count
      loading.close()
    },

    async deleteAttributeValues() {
      const values = this.startValue.attributeValues
      if (values && values.length) {
        const res = await delivery.ProductsCore.AttributeValuesActions.delete({
          productId: this.$route.params.id,
        })
        if (res.error) {
          alert('Не удалось удалить атрибуты товаров')
          return
        } else {
          this.startValue.attributeValues = []
        }
      }
    },

    async deleteAttributeOptions(data) {
      let indexOptions = -1
      let attributeValue

      this.startValue.attributeValues.forEach((value) => {
        if (value.attributeId === data.attributeId) {
          attributeValue = value.id
          indexOptions = value.attributeOptions.findIndex(
            (item) => item.id === data.id,
          )
        }
      })

      if (indexOptions >= 0) {
        const isConfirm = confirm(
          'Вы уверены, что хотите удалить этот вариант?',
        )
        if (!isConfirm) {
          this.attributes.forEach((value) => {
            if (value.id === data.attributeId) {
              value.valueOptions.push(data)
            }
          })
        } else {
          this.startValue.attributeValues.forEach((attr) => {
            attr.attributeOptions = attr.attributeOptions?.filter(
              (option) => option?.id !== data?.id,
            )
          })

          const res =
            await delivery.ProductsCore.AttributeValueOptionsActions.delete({
              attributeOptionId: data.id,
              attributeValueId: attributeValue,
            })
          if (res.error) {
            alert('Произошла ошибка удаления')
            return
          }
        }
      }
    },

    uploadImages(images) {
      this.form.images = images
    },

    resetForm() {
      this.form = {
        name: '',
        slug: '',
        seo: {},
      }
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.form.price) {
            this.showNotification(
              'К сожалению, товар не получиться обновить, т.к. цена в МС указана 0 или вообще не указана',
              'error',
            )
            return
          }

          const loading = this.$loading({
            lock: true,
          })

          this.form.attributeValues = this.attributes
            .filter((item) => {
              return item?.valueOptions?.length
            })
            .map((item) => {
              return {
                attributeId: item.id,
                attributeOptions: item.valueOptions,
              }
            })

          const data = { ...this.form }
          this.startValue.attributeValues = data.attributeValues

          this.deleteProductAssortments()
          this.deleteProductCategories()

          const result = await delivery.ProductsCore.ProductsActions.update(
            this.$route.params.id,
            data,
          )
          if (result.error) {
            alert('Ошибка редактирования продукта: ' + result.error)
          } else {
            alert('Продукт успешно редактирован')
          }
          
          this.getProduct()
          loading.close()
        }
      })
    },
    async SaveAndComeBack(form){
      await this.submitForm(form)
      this.$router.go(-1)
    },
    getProductStocksData() {
      const deliveryTexts = parseProductPresence(this.form.presence)
      return {
        stocks: this.form.stocks,
        isAvailable: this.form.isAvailable,
        showroomStateText: deliveryTexts.showroomStateText,
        deliveryText: deliveryTexts.deliveryText,
        selfDeliveryText: deliveryTexts.selfDeliveryText,
      }
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.products {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
    a {
      padding: 0 0.625rem;
      button {
        height: 100%;
      }
    }
  }
  .imageList {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    list-style-type: none;

    li > img {
      width: auto;
      height: 9.375rem;
      padding: 0.625rem;
    }
  }
  .block {
    & > div {
      width: fit-content;
      input {
        height: 2rem;
        width: 18.5rem;
      }
      svg {
        top: 1rem;
      }
    }
  }
  .prices {
    display: flex;
  }
  label {
    margin: 0;
  }
  .filterItem > label {
    width: auto !important;
  }
  .drawerItems {
    padding: 0 1rem;
    .drawerItem {
      margin: 0.5rem 0;
    }
    table {
      width: 100%;
      tr > td:first-child {
        font-weight: bold;
      }
    }
  }
}
</style>
